import routes from '../constants/routes'

export const halo = {
  title: `Request information`,
  root: `../../`,
  route: routes.terms,
}

export const intro = {
  ...halo,
}
